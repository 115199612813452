import * as React from "react"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {Helmet} from "react-helmet";

import pressKit from '../files/OneOfMore_Press_kit_2.pdf'
import pressKit2 from '../files/TZ_One_of_More_22.11.2021_cesky.pdf'

const IndexPage = () => (
    <Layout>
        <Helmet>
            <body className="bg-custom-black text-gray-100" />
        </Helmet>
        <Seo title="Film"/>

        <section className="relative px-0 sm:px-6 lg:px-8 hidden md:block">
            <StaticImage quality={100} layout="fullWidth" src="../images/movie/WEB_HOMEPAGE_VH2.jpg" alt="One Of More title poster"/>
        </section>

        <section className="relative px-0 sm:px-6 lg:px-8 md:hidden">
            <StaticImage quality={100} layout="fullWidth" src="../images/movie/WEB_HOMEPAGE_mobile_VH1.jpg" alt="One Of More title poster"/>
        </section>

        <section className="mx-auto min-w-sm w-full pb-8 mt-8">
            <a name="trailer"/>
            <div className="w-full text-center">
                <h4 className="text-xl">TRAILER</h4>
                ONE OF MORE Short film by David Van
            </div>

            <div className="md:w-1/2 mx-auto">
            <div className="aspect-w-16 aspect-h-9">
                <iframe
                    className="mx-auto mt-4 sm:w-full md:max-w-1/3"
                    src="https://www.youtube.com/embed/h5wKOGsgDG4"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
            </div>

            <StaticImage quality={100} className="mx-auto pt-16 md:pt-16" src="../images/movie/screenshot1_long.png" alt="screenshot"
                         imgStyle={{ objectFit: 'contain' }}
                         layout="fullWidth"
            />
        </section>
        <section className="relative px-0 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
                <a className="" name="about"/>
                <div className="grid grid-cols-1 gap-8 mx-auto w-4/5 md:grid-cols-2 sm:w-full">
                    <div>
                        <StaticImage
quality={100}                             className="border border-gray-500 rounded"
                            src="../images/movie/WEB_POSTER_VH1.jpg"
                            alt="poster"
                        />
                    </div>
                    <div>
                        <h4 className="mb-4 text-xl">ONE OF MORE: synopsis</h4>
                        <p>Emilie is a lonely middle-aged woman who is hiding in the basement of her house from the
                            ever-increasing danger of the world around her. The only way she keeps an eye on what's
                            really going on outside is through television news, which she watches most of her time.
                            After a few months, detached from reality and with dwindling food supplies, one day she is
                            faced with the decision to overcome her fear and go out.</p>
                        <p className="mt-12 text-sm">Written and Directed by David Van<br/>Cinematographer: Lukáš
                            Hausenblas
                        </p>
                        <p className="mt-4 text-sm">Starring: Andrea Miltner, Maxime Mededa</p>
                        <p className="mt-4 text-sm  ">Runtime: 22 minutes</p>
                    </div>
                </div>
            </div>


            <StaticImage quality={100} className="mx-auto md:pt-16" src="../images/movie/screenshot2_long.png" alt="screenshot"
                         imgStyle={{ objectFit: 'contain' }}
                         layout="fullWidth"
            />
        </section>
        <section className="relative px-0 sm:px-6 lg:px-8  mb-10">
            <div className="text-lg max-w-prose mx-auto">
                <a name="cast"/>
                <h4 className="w-full text-center text-xl mb-4">Cast & Crew</h4>

                <div className="grid grid-cols-1 md:gap-4 gap-y-12 mx-auto md:grid-cols-4">
                    <div>
                        <StaticImage quality={100} className="mx-auto" src="../images/movie/David_Van.jpg" alt="David Van photo"/>
                    </div>
                    <div className="p-4 md:p-0 col-span-3">
                        <h4 className="text-lg font-bold mb-4">David Van (Writter, Director & Producer)</h4>
                        David Van is a young director and screenwriter. For several years he worked in various positions
                        in film and theater, later he combined all his experience and began to use them fully in
                        directing and screenwriting. In recent years, he has made a number of music videos, commercials
                        and several short films. Previous short film Pa'lante: <a href="https://www.palanteshortfilm.com/">https://www.palanteshortfilm.com/</a>.
                    </div>
                    <div>
                        <StaticImage quality={100} className="mx-auto" src="../images/movie/Andrea_Miltner.jpg"
                                     alt="Andrea Miltner photo"/>
                    </div>
                    <div className="p-4 md:p-0 col-span-3">
                        <h4 className="text-lg font-bold mb-4">Andrea Miltner</h4>
                        Andrea Miltnerová is a dancer, actress and choreographer. Born and brought up in London, her
                        Czech roots drew her to Prague where she danced with the Ballet of the National Theatre. She
                        diversified into contemporary and baroque dance, performing both at home and abroad. She has
                        presented several of her solo choreographies at prestigious festivals and theatres throughout
                        Europe. Andrea has appeared in numerous films shot in the Czech Republic for example Blade II
                        and Hellboy (Guillermo del Torro) and Oliver Twist (Roman Polanski).
                    </div>

                    <div>
                        <StaticImage quality={100} className="mx-auto" src="../images/movie/Maxime_Mededa.jpg"
                                     alt="Maxime Mededa photo"/>
                    </div>
                    <div className="p-4 md:p-0 col-span-3">
                        <h4 className="text-lg font-bold mb-4">Maxime Mededa</h4>
                        He has studied Journalism at the faculty of social sciences, Charles university in Prague.
                        Specializing in television sports commentator. However, he never devoted himself to this field.
                        his great interest is Theater and film.He plays in more or less then in many theaters here like
                        an actor. He comes from Benin a small french speaking country in west Afrika but has already
                        spent half of his life in Czech Republic.
                    </div>

                    <div>
                        <StaticImage quality={100} className="mx-auto" src="../images/movie/Petr_Wajsar.jpg" alt="Petr Wajsar photo"/>
                    </div>
                    <div className="p-4 md:p-0 col-span-3">
                        <h4 className="text-lg font-bold mb-4">Petr Wajsar (Composer)</h4>
                        Composer, arranger, singer, piano player, guitar player, bass-guitar player and lyricist. Born
                        in Prague, November 14, 1978s.
                    </div>

                    <div>
                        <StaticImage quality={100} className="mx-auto" src="../images/movie/Daniel_Valasek.jpg"
                                     alt="Dan Valášek photo"/>
                    </div>
                    <div className="p-4 md:p-0 col-span-3">
                        <h4 className="text-lg font-bold mb-4">Dan Valášek (Sound)</h4>
                        Daniel Valášek is studying Sound department at FAMU in Prague. In addition to film sound, he
                        specializes in music and soundscapes.
                    </div>

                    <div>
                        <StaticImage quality={100} className="mx-auto" src="../images/movie/Howard_Lotker.jpg"
                                     alt="Howard Lotker photo"/>
                    </div>
                    <div className="p-4 md:p-0 col-span-3">
                        <h4 className="text-lg font-bold mb-4">Howard Lotker (actor / singer / dancer / dramaturg / director / performance researcher / translator)</h4>
                        He has more than 30 years of extensive performing experience, working for many leading companies: as a speaker for documentaries and phone systems; character actor for theater, film video games, commercials and animated films; English language dubbing for films; singing in musicals, with the Swing Orchestra ZaTrest Band and others. He has worked in film projects across from stars like Edward Norton (The Illusionist) and Heath Ledger (Knight's Tale).
                    </div>

                    <div>
                        <StaticImage quality={100} className="mx-auto" src="../images/movie/Lukas_Hausenblas.jpg"
                                     alt="Lukáš Hausenblas photo"/>
                    </div>
                    <div className="p-4 md:p-0 col-span-3">
                        <h4 className="text-lg font-bold mb-4">Lukáš Hausenblas (Director of Photography &
                            Producer)</h4>

                        Lukáš Hausenblas is a cinematographer who shoots music videos and feature films. During his
                        studies at FDULS in Pilsen, he began making his own experimental films, on which he perfected
                        his skills and thus discovered the boundaries of painting.
                    </div>

                    <div>
                        <StaticImage quality={100} className="mx-auto" src="../images/movie/Anna_Karaivanova.png"
                                     alt="Anna Karaivanova photo"/>
                    </div>
                    <div className="p-4 md:p-0 col-span-3">
                        <h4 className="text-lg font-bold mb-4">Anna Karaivanova (Costume Designer)</h4>

                    </div>
                    <div>
                        <StaticImage quality={100} className="mx-auto" src="../images/movie/Adela_Hakova.png"
                                     alt="Adéla Háková photo"/>
                    </div>
                    <div className="p-4 md:p-0 col-span-3">
                        <h4 className="text-lg font-bold mb-4">Adéla Háková (Make-up and Hair)</h4>

                    </div>

                    <div>
                        <StaticImage quality={100} className="mx-auto" src="../images/movie/Jakub_Rikovski.png"
                                     alt="Jakub Řikovský photo"/>
                    </div>
                    <div className="p-4 md:p-0 col-span-3">
                        <h4 className="text-lg font-bold mb-4">Jakub Řikovský (Film Editor)</h4>

                    </div>

                    <div>
                        <StaticImage quality={100} className="mx-auto" src="../images/movie/Michal_Horacek.png"
                                     alt="Michal Hor photo"/>
                    </div>
                    <div className="p-4 md:p-0 col-span-3">
                        <h4 className="text-lg font-bold mb-4">Michal Hor (Lighting Director)</h4>

                    </div>

                    <div>
                        <StaticImage quality={100} className="mx-auto" src="../images/movie/Matous_Janicek.png"
                                     alt="Matouš Janíček photo"/>
                    </div>
                    <div className="p-4 md:p-0 col-span-3">
                        <h4 className="text-lg font-bold mb-4">Matouš Janíček (Production Designer)</h4>

                    </div>
                </div>
            </div>



        </section>

        <section className="relative px-0 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
                <a name="interview"/>
                <h4 className="w-full text-center text-xl mb-4">Interview</h4>

                <div className="grid grid-cols-1 md:gap-4 gap-y-12 mx-auto md:grid-cols-1">
                    <div className="p-4 md:p-0 col-span-3  mb-8">
                        <h4 className="text-lg font-bold mb-4">David Van</h4>
                        <div className="aspect-w-16 aspect-h-9">
                            <iframe
                                className="mx-auto mt-4 sm:w-full md:max-w-1/3"
                                src="https://www.youtube.com/embed/98_paQtFtXM"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="p-4 md:p-0 col-span-3 mb-8">
                        <h4 className="text-lg font-bold mb-4">Andrea Miltner</h4>
                        <div className="aspect-w-16 aspect-h-9">
                            <iframe
                                className="mx-auto mt-4 sm:w-full md:max-w-1/3"
                                src="https://www.youtube.com/embed/PCAWvoOzvCg"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="p-4 md:p-0 col-span-3 mb-8">
                        <h4 className="text-lg font-bold mb-4">Lukáš Hausenblas</h4>
                        <div className="aspect-w-16 aspect-h-9">
                            <iframe
                                className="mx-auto mt-4 sm:w-full md:max-w-1/3"
                                src="https://www.youtube.com/embed/oI-h8ZbNUyY"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="p-4 md:p-0 col-span-3 mb-8">
                        <h4 className="text-lg font-bold mb-4">Petr Wajsar a Daniel Valášek</h4>
                        <div className="aspect-w-16 aspect-h-9">
                            <iframe
                                className="mx-auto mt-4 sm:w-full md:max-w-1/3"
                                src="https://www.youtube.com/embed/ucg9tqlMd4I"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="p-4 md:p-0 col-span-3 mb-8">
                        <h4 className="text-lg font-bold mb-4">Anna Karaivanova a Adéla Háková</h4>
                        <div className="aspect-w-16 aspect-h-9">
                            <iframe
                                className="mx-auto mt-4 sm:w-full md:max-w-1/3"
                                src="https://www.youtube.com/embed/KqKRK_dxkWE"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>

            </div>

        </section>

        <section className="relative px-0 sm:px-6 lg:px-8 pt-4 md:pt-16">
            <StaticImage
quality={100}                 className="mx-auto"
                src="../images/movie/OneOfMore_backstage_kolaz_web_2_mensi.jpg"
                alt="backstage collage"
                imgStyle={{ objectFit: 'contain' }}
                layout="fullWidth"
            />
            <div className="grid grid-cols-1 text-lg max-w-prose mx-auto bg-gray-100 text-gray-900 text-center pb-8">
                <a name="press"/>
                <h4 className="text-2xl mx-auto mb-8 uppercase">Press</h4>


                <a className="mb-4" href={pressKit}>
                    <StaticImage quality={100} src="../images/pdf.png" alt="One Of More title poster"/><br/>
                    PDF PRESS kit
                </a>

                <a className="mb-4" href={pressKit2}>
                    <StaticImage quality={100} src="../images/pdf.png" alt="One Of More title poster"/><br/>
                    Press release One of More 22. 11. 2021 Czech (Tisková zpráva/Česky)
                </a>

                <a href="https://www.facebook.com/oneofmoreshortfilm"
                   className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                    <StaticImage quality={100} src="../images/fb_black.png" alt="Facebook" />
                </a>
                <a
                    href="https://instagram.com/oneofmoreshortfilm"
                    className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
                >
                    <StaticImage quality={100} src="../images/ig_black.png" alt="Instagram" />
                </a>
            </div>
        </section>
        <section className="relative px-0 sm:px-6 lg:px-8">

            <StaticImage
quality={100}                 imgStyle={{ objectFit: 'contain' }}
                className="mx-auto md:pt-16" src="../images/movie/screenshot3_long.png" alt="screenshot" layout="fullWidth" />
            <StaticImage
quality={100}                 imgStyle={{ objectFit: 'contain' }}
                className="mx-auto" src="../images/movie/screenshot4_long.png" alt="screenshot" layout="fullWidth"/>
            <StaticImage
quality={100}                 imgStyle={{ objectFit: 'contain' }}
                className="mx-auto " src="../images/movie/WEB_zapati_vh1.png" alt="film credits" layout="fullWidth" />
        </section>

    </Layout>
)

export default IndexPage
